<template>
  <div class="wx-login">
    <div class="back-direction" @click="wxLoginBack">
      <img src="../assets/images/back-direction.png" alt="返回" />
    </div>
    <div class="wx-login-container">
      <p>微信登录</p>
      <img src="../assets/images/wx.jpg" alt="微信" />
      <div class="wx-login-tips">请使用微信扫码登陆</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "wxLogin",
  methods: {
    wxLoginBack() {
      this.$router.go(-1);
    },
  },
  data() {
    return {};
  },
  created() {},
};
</script>

<style scoped lang="scss">
.wx-login {
  position: relative;
  background-color: #000;
  width: 100%;
  height: 100%;
  .back-direction {
    position: absolute;
    top: 66px;
    left: 75px;
    cursor: pointer;
    img {
      width: 73px;
      height: 66px;
    }
  }
  .wx-login-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      font-weight: 700;
      font-size: 40px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    img {
      margin: 60px 0;
    }
    .wx-login-tips {
      width: 631px;
      height: 109px;
      font-size: 32px;
      text-align: center;
      line-height: 109px;
      background: #222222;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 93.5px;
    }
  }
}
</style>
